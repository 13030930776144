<template>
  <div class="pt-5 px-2">
    <v-row>
      <v-col cols="12" lg="8" md="8" sm="8">
        <v-form ref="form" v-model="valid">
          <v-container class="mt-6">
            <v-row>
              <v-col cols="12" md="7" sm="7" lg="7" class="py-1">
                <v-text-field outlined dense hide-details label="Subject" v-model="reportObj.subject" :rules="setValidation('requiredValidation')"></v-text-field>
              </v-col>
              <v-col cols="12" md="7" sm="7" lg="7" class="py-1">
                <v-select outlined dense hide-details label="Type" v-model="reportObj.type" item-text="text" item-value="id" :rules="setValidation('requiredValidation')" :items="supportTypes"></v-select>
              </v-col>
              <v-col cols="12" sm="7" md="7" lg="7" class="py-1">
                <v-textarea outlined dense hide-details label="Message" v-model="reportObj.message" class="pt-3 pb-3" :rules="setValidation('requiredValidation')"></v-textarea>
              </v-col>
              <v-col cols="12" sm="7" md="7" lg="7" class="py-1">
                <v-btn :disabled="!valid" color="success" class="mr-4" @click="SaveUpdateHandler"> Send </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-col>
      <v-col cols="12" lg="4" md="4" sm="4">
        <v-card outlined height="350">
          <v-card-title>Customer Support History</v-card-title>
          <v-divider></v-divider>
          <v-list max-height="300" style="overflow-y: auto" v-if="listOfRequests.length">
            <v-list-item two-line v-for="i in listOfRequests" :key="i._id" >
            <v-list-item-avatar> <img src="https://image.freepik.com/free-vector/customer-service-support-logo-icon-client-assistance-help-desk-operator-agent-headset-headphones-call-center-symbol-flat_101884-949.jpg"> </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{i.subject}}</v-list-item-title>
                <v-list-item-subtitle>{{i.message}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-card-text v-else class="px-2 py-11">There are No Records Found ...</v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data () {
    return {
      valid: false,
      reportObj: {},
      listOfRequests: [],
      supportTypes: [
        { id: 1,text:'Feedback'},
        { id: 2,text:'Request'},
        { id: 3,text:'Complaint'},
        { id: 4,text:'Suggestions'},
        { id: 5,text:'Others'},
      ]
    }
  },
  mounted () {
    this.getListOfRequests()
  },
  methods: {
    SaveUpdateHandler() {
      this.$_execute('post', 'contactDev', this.reportObj).then(() => {
        this.reportObj = {}
        this.$refs.form.resetValidation()
        this.getListOfRequests()
        this.$root.$emit('snackbar', { message: 'Sent Successfully', color: 'success' })
      })
    },
    getListOfRequests() {
      this.$_execute('get', 'contactDev').then(response => {
        this.listOfRequests = response.data
      })
    }
  }
}
</script>

<style scoped></style>
